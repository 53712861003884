<template>
  <v-container>
    <template
      v-for="(log, i) in dataTimeline"
    >
      <v-row
        v-if="dataTimeline.length === 1"
        :key="'timeline-header-' + (i + 1)"
        class="mb-n6"
      >
        <v-col>
          <span class="timeline-circle" />

          <span
            v-if="module === 'production'"
            class="timeline-content-title text-subtitle-2"
          >
            {{ log.entity }}
          </span>

          <span
            v-if="module === 'withdrawal'"
            class="timeline-content-title text-subtitle-2"
          >
            {{ log.user }}
          </span>

          <p class="timeline-content-subtitle text-caption">
            {{ log.time }}
          </p>

          <p
            v-if="module === 'production'"
            class="timeline-content font-weight-bold"
          >
            {{ log.desc }}
          </p>

          <span
            v-if="module === 'withdrawal'"
            class="timeline-content font-weight-bold"
          >
            {{ log.status }}
          </span>

          <hr
            v-if="module === 'production'"
            class="timeline-hr-production"
          >
          <hr
            v-if="module === 'withdrawal'"
            class="timeline-hr-withdrawal"
          >
        </v-col>
      </v-row>

      <v-row
        v-if="dataTimeline.length > 1"
        :key="'timeline-content-' + (i + 1)"
        class="mb-n6"
      >
        <v-col>
          <span class="timeline-circle" />

          <span
            v-if="module === 'production'"
            class="timeline-content-title text-subtitle-2"
          >
            {{ log.entity }}
          </span>

          <span
            v-if="module === 'withdrawal'"
            class="timeline-content-title text-subtitle-2"
          >
            {{ log.user }}
          </span>

          <p class="timeline-content-subtitle text-caption">
            {{ log.time }}
          </p>

          <p
            v-if="module === 'production'"
            class="timeline-content font-weight-bold"
          >
            {{ log.desc }}
          </p>

          <span
            v-if="module === 'withdrawal'"
            class="timeline-content font-weight-bold"
          >
            {{ log.status }}
          </span>

          <hr
            v-if="module === 'production'"
            class="timeline-hr-production"
          >
          <hr
            v-if="module === 'withdrawal'"
            class="timeline-hr-withdrawal"
          >
        </v-col>
      </v-row>

      <v-row
        v-if="dataTimeline.length > (i + 1)"
        :key="'timeline-line-' + (i + 1)"
        class="mb-n6"
      >
        <v-col>
          <div class="timeline-line" />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['dataTimeline', 'module'],

    data: () => ({
      name: 'TimeLine',
    }),

    computed: {
    },

    watch: {
    },

    created () {
    },

    methods: {
    },
  }
</script>

<style scoped>
  .timeline-line {
    border-left: 2px solid #C4C4C4;
    height: 90px;
    margin-left: 7px;
    margin-top: -65px;
    margin-bottom: 10px;
  }

  .timeline-circle {
    height: 15px;
    width: 15px;
    background-color: #C4C4C4;
    border-radius: 50%;
    display: inline-block;
  }

  .timeline-content-title {
    margin-left: 15px;
  }

  .timeline-content-subtitle {
    margin-left: 30px;
  }

  .timeline-content {
    margin-left: 30px;
  }

  .timeline-hr-production {
    width: 540px;
    margin-left: 30px;
    margin-top: 5px;
  }

  .timeline-hr-withdrawal {
    width: 200px;
    margin-left: 30px;
    margin-top: 5px;
  }
</style>
